<template>
  <card-container backButton closeButton>
    <div class="flex flex-col items-center h-full px-12 pb-10 pt-28">
      <div class="text-3xl">Endringer lagret!</div>
      <div class="mt-6">
        Det kan ta opptil 2 minutter før endret profilbilde eller brukernavn vises på steder du har
        delt.
      </div>
      <button
        @click="$router.push({ name: 'menu', params: { transition: 'slide-forward' } })"
        class="px-12 py-3 text-xl border-2 rounded-full mt-14"
      >
        OK
      </button>
    </div>
  </card-container>
</template>
<script>
import CardContainer from '../components/CardContainer.vue'
export default {
  name: 'UserEditConfirmation',
  components: { CardContainer },
}
</script>
